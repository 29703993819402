<script setup>

</script>

<template>
    <div class="ThirdlyScreen">
        <img src="@/assets/thirdly/leftBg.png" alt="" class="leftBg">
        <div class="content">
            <div class="title">Comprehensive protection, your network guardian</div>
            <div class="itemBox">
                <img src="@/assets/thirdly/tri.png" alt="">
                <div class="item">
                    <span>Multi layered protection system:</span>
                    Adopting advanced multi-level defense mechanisms, from the source to the end, it builds an indestructible protective barrier for
                    your
                    online experience.
                </div>
            </div>
            <div class="itemBox">
                <img src="@/assets/thirdly/tri.png" alt="">
                <div class="item">
                    <span>Intelligent security scanning:</span>
                    Automated network health checks, instantly detecting potential threats, ensuring that your devices and data are always in the
                    safest
                    state.
                </div>
            </div>
            <div class="itemBox">
                <img src="@/assets/thirdly/tri.png" alt="">
                <div class="item">
                    <span>Information Encryption Shield:</span>
                    Utilizing leading information encryption technology, your online footprint is invisible, protecting your privacy from prying into.
                </div>
            </div>
        </div>

        <img src="@/assets/thirdly/wifi.png" alt="" class="wifi">
        <img src="@/assets/thirdly/speed.png" alt="" class="speed">
        <img src="@/assets/thirdly/ip.png" alt="" class="ip">
    </div>
</template>

<style scoped lang="scss">
.ThirdlyScreen {
    position: relative;
    height: 960px;
    padding-top: 192px;
    overflow: hidden;

    .leftBg {
        position: absolute;
        left: 0;
        top: 0;
        width: 1000px;
        height: 100%;
    }

    .content {
        padding-left: 260px;

        .title {
            margin-bottom: 68px;
            font-weight: 700;
            font-size: 40px;
            color: #FFFFFF;
            line-height: 53px;
        }

        .itemBox {
            display: flex;
            align-items: flex-start;
            width: 768px;
            margin-bottom: 60px;

            img {
                width: 28px;
                height: 28px;
                margin-right: 15px;
            }

            .item {
                font-weight: 700;
                font-size: 24px;
                color: #9E9E9E;
                line-height: 36px;

                span {
                    color: #ffffff;
                }
            }
        }
    }

    .wifi, .speed, .ip {
        position: absolute;

    }

    .wifi {
        top: 0;
        right: -80px;
        width: 702px;
        height: 462px;
    }

    .speed {
        top: 150px;
        right: -150px;
        width: 906px;
        height: 576px;
    }

    .ip {
        top: 300px;
        right: -200px;
        width: 1062px;
        height: 663px;
    }
}
</style>