<template>
    <div class="home">
        <FirstScreen/>
        <OurCharacteristics/>
        <ThirdlyScreen/>
        <FourthlyScreen/>
        <BottomBar/>
    </div>
</template>

<script>
import FirstScreen from '@/components/FirstScreen.vue';
import OurCharacteristics from '@/components/OurCharacteristics.vue';
import ThirdlyScreen from '@/components/ThirdlyScreen.vue';
import FourthlyScreen from '@/components/FourthlyScreen.vue';
import BottomBar from '@/components/BottomBar.vue';

export default {
    name: 'HomeView',
    components: {
        BottomBar,
        FourthlyScreen,
        ThirdlyScreen,
        OurCharacteristics,
        FirstScreen
    }
};
</script>
