<script setup>

</script>

<template>
    <div class="fourthlyScreen">
        <div class="content">
            <div class="title">Latest Update Just For you</div>
            <div class="btn">Download-></div>
        </div>
    </div>
</template>

<style scoped lang="scss">
.fourthlyScreen {
    padding: 246px 0 218px;
    text-align: center;

    .content {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 1400px;
        height: 466px;
        margin: 0 auto;
        background: url("@/assets/fourthly/fourthlyBg.png");
        background-size: cover;

        .title {
            margin-bottom: 60px;
            font-weight: 700;
            font-size: 40px;
            color: #FFFFFF;
            line-height: 25px;
        }

        .btn {
            padding: 15px 30px;
            background: rgba(0, 0, 0, 0.75);
            border-radius: 43px;
            font-size: 24px;
            color: #FFFFFF;
            line-height: 25px;
            cursor: pointer;
        }
    }
}
</style>