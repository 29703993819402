import {createRouter, createWebHashHistory} from 'vue-router';
import HomeView from '../views/HomeView.vue';

const routes = [
    {
        path: '/',
        name: 'home',
        component: HomeView
    },
    {
        path: '/policy',
        name: 'policy',
        component: () => import('../views/PolicyView.vue')
    },
    {
        path: '/terms',
        name: 'terms',
        component: () => import('../views/TermsView.vue')
    },
];

const router = createRouter({
    history: createWebHashHistory(),
    routes
});

export default router;
