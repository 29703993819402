<script setup>
</script>

<template>
    <div class="firstScreen">
        <video src="@/assets/video.mp4" class="videoBox" autoplay :loop="true" muted width="100%"></video>
        <img src="@/assets/first/firstBg.png" alt="" class="navBg">

        <div class="content">
            <div class="text">Super user-friendly network agent with</div>
            <div class="text">One click access</div>
            <div class="text">Fly Proxy Pro can protect your privacy, ensure the security of your network information</div>

            <div class="downImg">
                <!--                <img src="@/assets/first/google.png" alt="">-->
                <!--                <img src="@/assets/first/appstore.png" alt="">-->
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
.firstScreen {
    position: relative;
    height: 937px;

    .videoBox {
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .navBg {
        position: absolute;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .content {
        position: relative;
        width: 650px;
        padding-top: 265px;
        margin-left: 260px;
        text-align: left;
        z-index: 2000;

        .text:nth-child(1) {
            margin-bottom: 6px;
            font-weight: 700;
            font-size: 64px;
            line-height: 84px;
            color: #FFFFFF;
        }

        .text:nth-child(2) {
            background-image: linear-gradient(to right, #A987FF, #2B5BFF, #4AABF3);
            color: transparent;
            background-size: cover;
            background-position: center;
            background-clip: text;
            margin-bottom: 24px;
            font-weight: 700;
            font-size: 72px;
            line-height: 95px;
            border-bottom: 1px solid #5E94FF;
        }

        .text:nth-child(3) {
            margin-bottom: 90px;
            font-size: 24px;
            color: #686D8B;
            line-height: 34px;
        }

        .downImg {
            display: flex;

            img {
                width: 323px;
                height: 91px;
                cursor: pointer;

                & ~ img {
                    margin-left: 18px;
                }
            }
        }
    }
}
</style>