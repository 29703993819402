<script setup>
import {useRouter} from 'vue-router';

const router = useRouter();

let handleToHome = () => {
    router.push("/");
};

let handleToPolicy = () => {
    router.push("/policy");
};

let handleToTerms = () => {
    router.push("/terms");
};

</script>

<template>
    <div class="tabBar">
        <div class="logoBox">
            <img src="@/assets/logo.png" alt="" class="logo">
            <span class="name">Fly Proxy Pro</span>
        </div>
        <nav>
            <div class="home" :class="{active:router.currentRoute.value.name === 'home'}" @click="handleToHome">
                <span>Home</span>
            </div>
            <div class="policy" :class="{active:router.currentRoute.value.name === 'policy'}" @click="handleToPolicy">
                <span>Privacy Policy</span>
            </div>
            <div class="policy" :class="{active:router.currentRoute.value.name === 'terms'}" @click="handleToTerms">
                <span>Terms of Use</span>
            </div>
            <!--            <div class="download">Download Now</div>-->
        </nav>
    </div>
</template>

<style scoped lang="scss">
.tabBar {
    position: absolute;
    top: 0;
    z-index: 3000;
    width: 100%;
    padding: 20px 260px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .logoBox {
        display: flex;
        align-items: center;

        .logo {
            width: 81px;
            height: 83px;
        }

        .name {
            margin-left: 13px;
            font-size: 36px;
            color: #FFFFFF;
            line-height: 50px;
            text-align: center;
            text-transform: none;
        }

        img ~ img {
            margin-left: 24px;
        }
    }

    nav {
        display: flex;
        align-items: center;

        .home, .policy {
            padding: 12px 0;
        }

        div {
            font-size: 24px;
            line-height: 34px;
            color: #FFFFFF;
            cursor: pointer;
            text-align: center;
        }

        .home {
            width: 149px;
            margin-right: 42px;
        }

        .policy {
            width: 204px;
        }

        .active {
            background-image: url('@/assets/navBg.png');
            background-size: 100% 100%;

            span {
                background-image: linear-gradient(to right, #A987FF, #2B5BFF, #4AABF3);
                color: transparent;
                background-size: cover;
                background-position: center;
                background-clip: text;
            }
        }

        .download {
            padding: 15px 48px;
            border-radius: 50px;
            background: #5E94FF;
        }
    }
}
</style>