<script setup>

</script>

<template>
    <div class="ourCharacteristics">
        <div class="title">Our characteristics</div>
        <div class="characteristicsImg">
            <img src="@/assets/characteristics/characteristics1.png" alt="">
            <img src="@/assets/characteristics/characteristics2.png" alt="">
            <img src="@/assets/characteristics/characteristics3.png" alt="">
        </div>
        <div class="phoneImg">
            <img src="@/assets/characteristics/phoneImg1.png" alt="">
            <img src="@/assets/characteristics/phoneImg2.png" alt="">
            <img src="@/assets/characteristics/phoneImg3.png" alt="">
        </div>
    </div>
</template>

<style scoped lang="scss">
.ourCharacteristics {
    padding: 80px 260px;

    .title {
        margin-bottom: 44px;
        font-weight: 700;
        font-size: 40px;
        color: #FFFFFF;
        line-height: 53px;
    }

    .characteristicsImg {
        display: flex;
        justify-content: space-between;
        margin-bottom: 50px;

        img {
            width: 431px;
            height: 229px;
        }
    }

    .phoneImg {
        display: flex;
        justify-content: space-between;
        padding: 0 64px;

        img {
            width: 304px;
            height: 628px;
        }
    }
}
</style>