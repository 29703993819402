<script setup>

</script>

<template>
    <div class="bottom">
        <div class="top">
            <div class="logoBox">
                <img src="@/assets/logo.png" alt="" class="logo">
                <span class="name">Fly Proxy Pro</span>
            </div>
            <nav>
                <div class="home active"><span>Home</span></div>
                <div class="policy">Privacy Policy</div>
            </nav>
        </div>
        <div class="last">
            <span>Copyright@2024</span>
        </div>
    </div>
</template>

<style scoped lang="scss">
.bottom {
    padding: 22px 260px;

    .top {
        display: flex;
        justify-content: space-between;
        margin-bottom: 78px;

        .logoBox {
            display: flex;
            align-items: center;

            .logo {
                width: 55px;
                height: 57px;
            }

            .name {
                margin-left: 13px;
                font-size: 36px;
                color: #FFFFFF;
                line-height: 50px;
                text-align: center;
                text-transform: none;
            }
        }

        nav {
            display: flex;
            align-items: center;

            .home {
                margin-right: 60px;
                font-weight: 400;
                font-size: 18px;
                color: #FFFFFF;
                line-height: 24px;
            }

            .policy {
                font-size: 18px;
                color: rgba(255, 255, 255, 0.48);
                line-height: 24px;
            }
        }
    }

    .last {
        border-top: 1px dotted #3E3E3E;
        padding-top: 40px;

        font-size: 18px;
        color: #3E3E3E;
        line-height: 24px;
    }
}
</style>